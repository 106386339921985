
import { defineComponent } from "vue";
import RolesCatalogue from "@/components/catalogues/RoleCatalogue/RoleCatalogue.vue";

export default defineComponent({
  components: { RolesCatalogue },
  props: {},
  methods: {},
  data() {
    return {};
  },
});
